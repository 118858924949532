.NavbarItems {
  /* background: linear-gradient(90deg, rgb(110,94,254) 0%, rgba(73,63,252,1) 100%); */
  background: white;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
}

.navbar-logo {
  color: #003140;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  /* float: left; */
}

.fa-user-circle {
  margin-left: 0.5rem;
  font-size: 1.4rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 50vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-links {
  color: #003140;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-links:hover {
  color: #25c5f2;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}
.resume-button{
  margin-left: -1rem;
  padding: 8px 24px;
  border-radius: 5px;
  color: #fff; 
  background-color: #003140;

}
.resume-button:hover {
  transition: all 0.3s ease-out;
  color: #003140; 
  background-color: #fff;
  transition: 250ms;
}

.fa-bars {
  color: #003140;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

/* #arrow-right{
    margin-left: 6px;
    display: inline-flex;
} */

@media screen and (max-width: 867px) {
  .nav-menu {
    width: 40vw;
    grid-gap: 0px;
  }
}

@media screen and (max-width: 867px) {
  .NavbarItems {
    position: relative;
  }
  .navbar-logo {
    color: #003140;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
  }

  .fa-user-circle {
    margin-left: 0.5rem;
    font-size: 1.4rem;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 110vw;
    height: 500px;
    position: absolute;
    top: 61px;
    left: -200%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background-color: #fff;
    left: -7.7%;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: left;
    padding: 2rem;
    width: 100%;
    display: table;
    color: #003140;
  }

  .nav-links:hover {
    background-color: #003140;
    border-radius: 0;
  }
  .navbar-logo {
    position: absolute;
    top: -20px;
    left: -50px;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #003140;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin-top: 2.2rem;
    margin-left: 1.5rem;
    margin-bottom: 3rem;
    border-radius: 4px;
    width: 30%;
    background: #003140;
    text-decoration: none;
    color: white;
    font-size: 1.5rem;
    align-items: left;
  }

  .nav-links-mobile:hover {
    background: white;
    color: #003140;
    transition: 250ms;
  }

  Button {
    display: none;
  }
  .resume-button{
    display: none;
  }
}

@media screen and (max-width: 556px) {
  .nav-menu.active {
    background-color: #fff;
    left: -10.7%;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100.99vw;
    height: 500px;
    position: absolute;
    top: 61px;
    left: -200%;
    opacity: 1;
    transition: all 0.5s ease;
  }
}
