.footer-container{
    padding-top: 2rem ;
    padding-bottom: 3rem;
    color: white;
    text-align: center;
}
a{
    color: white;
}

@media screen and (max-width: 500px) {
    .footer-container{
        padding-bottom: 3rem;
        color: white;
        text-align: center;
        margin: 0 2rem;
    }
}