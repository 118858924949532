body {
  animation: transitionIn 0.75s;
}
.container {
  padding-top: 10rem;
  display: flex;
  justify-content: center;
}

/* DEMO-SPECIFIC STYLES */
.typewriter h1 {
  color: #fff;
  font-family: monospace;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid orange; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.35em; /* Adjust as needed */
  animation: typing 4s steps(40, end), blink-caret 0.5s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}

@media (max-width: 426px) {
  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
  .typewriter h1 {
    color: #fff;
    font-family: monospace;
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: 0.15em solid orange; /* The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    letter-spacing: 0.15em; /* Adjust as needed */
    font-size: 1.35rem;
    margin: 5rem auto;
    animation: typing 4s steps(40, end), blink-caret 0.5s step-end infinite;
  }
}
@media (min-width: 768px) {
  .container {
    padding-top: 10rem;
    display: flex;
    justify-content: center;
    display: grid;
  }
  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
  .typewriter h1 {
    color: #fff;
    font-family: monospace;
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: 0.15em solid orange; /* The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 5rem auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: 0.25em; /* Adjust as needed */
    animation: typing 4s steps(40, end), blink-caret 0.5s step-end infinite;
  }
}
@media (max-width: 767px) {
  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
  .typewriter h1 {
    color: #fff;
    font-family: monospace;
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: 0.15em solid orange; /* The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    letter-spacing: 0.15em; /* Adjust as needed */
    font-size: 1.35rem;
    margin: 5rem auto;
    animation: typing 4s steps(40, end), blink-caret 0.5s step-end infinite;
  }
}

@media (max-width: 500px) {
  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
  .typewriter h1 {
    color: #fff;
    font-family: monospace;
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: 0.15em solid orange; /* The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    letter-spacing: 0.15em; /* Adjust as needed */
    font-size: 1.2rem;
    margin: 5rem auto;
    animation: typing 4s steps(40, end), blink-caret 0.5s step-end infinite;
  }
}
