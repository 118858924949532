.contact-container {
  width: 100%;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 7.5rem;
  padding-bottom: 9.5rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  background-color: white;
}

#user-circle {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #003140;
  margin-right: auto;
  margin-left: auto;
  width: 2.5rem;
  font-size: 2.5rem;
}

.contact-header {
  font-size: 3.5rem;
  line-height: 2.25rem;
  font-weight: 500;
  margin-bottom: 2rem;
}

.contact-get-in-touch {
  font-size: 2rem;
  line-height: 2.25rem;
  font-weight: 500;
  margin-bottom: 2rem;
}

.contact-looking-for-work {
  margin-left: auto;
  margin-right: auto;
  font-size: 1rem;
  line-height: 1.7rem;
  margin-bottom: 5rem;
  width: 50%;
  max-width: 810px;
  text-align: center;
}

.email-link {
  background-color: #003140;
  padding: 1.5rem 3.5rem;
  border-radius: 0.5rem;
  color: white;
  font-weight: 600;
  font-size: x-large;
}
.font-awesome-socials {
  padding-top: 4rem;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
}

#linkedin {
  margin-right: 1rem;
  color: black;
}
#github {
  margin-right: 1rem;
  color: black;
}
#medium {
  color: black;
}

@media (max-width: 500px) {
  .contact-container {
    width: 90%;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-top: 2.5rem;
    padding-bottom: 9.5rem;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    background-color: white;
  }

  .contact-looking-for-work {
    margin-left: auto;
    margin-right: auto;
    font-size: 1rem;
    line-height: 1.7rem;
    margin-bottom: 5rem;
    width: 90%;
    text-align: center;
  }
}
