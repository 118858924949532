.about-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  margin-top: 7.5rem;
  margin-bottom: 10.5rem;
  flex-direction: column;
  align-items: center;
}
.about-menu {
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
  padding-left: 2.5rem;
  align-items: center;
  text-align: center;
}

.introduction-text {
  color: white;
  font-size: 1.875rem;
  line-height: 2.25rem;
  margin-bottom: 1rem;
  font-weight: 500;
}

.introduction-text1 {
  overflow: hidden;
  color: white;
}

.description-text {
  margin-bottom: 2rem;
  line-height: 1.625;
  color: white;
}

.about-buttons {
  display: flex;
  justify-content: center;
  white-space: nowrap;
}

.about-button-work-with {
  display: inline-flex;
  color: white;
  background-color: rgb(0, 153, 255);
  font-weight: 600;
  border-width: 0px;
  padding-left: 1.7rem;
  padding-right: 1.7rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 0.25rem;
  font-size: large;
}

.about-button-work-with:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.about-button-work-with:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
}

.about-button-past-work {
  margin-left: 1rem;
  display: inline-flex;
  font-weight: 600;
  --tw-text-opacity: 1;
  color: #003140;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-width: 0px;
  padding-left: 1.4rem;
  padding-right: 1.4rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 0.25rem;
  font-size: large;
}

.about-button-past-work:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.about-button-past-work:hover {
  color: #fff;
  background-color: #003140;
  outline: 2px solid;
}

.image-on-right-side-about-section {
  width: 83.3333%;
  margin-right: 2.5rem;
}

/* FIX the way the image resizes when the browser is spread out fully on large screen. */

.cartoon-image {
  object-fit: contain;
  object-position: center;
  border-radius: 0.25rem;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .about-container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: 2.5rem;
    margin-bottom: 10.5rem;
    flex-direction: column;
    align-items: center;
  }
  .about-menu {
    margin-bottom: 4rem;
    align-items: center;
    text-align: center;
    margin-left: 0rem;
    padding-left: 0rem;
  }

  .introduction-text {
    color: white;
    font-size: 1.875rem;
    line-height: 2.25rem;
    margin-bottom: 1rem;
    font-weight: 500;
  }

  .introduction-text1 {
    overflow: hidden;
    color: white;
  }
  .description-text {
    color: white;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    display: grid;
    width: 50%;
  }
  .image-on-right-side-about-section {
    width: 83.3333%;
    display: grid;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 550px) {
  .introduction-text {
    color: white;
    font-size: 1.875rem;
    line-height: 2.25rem;
    margin-bottom: 1rem;
    font-weight: 500;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .description-text {
    color: white;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    display: grid;
    width: 90%;
  }
  .about-buttons {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    justify-content: center;
    white-space: nowrap;
  }

  .about-button-work-with {
    display: inline-flex;
    color: white;
    background-color: rgb(0, 153, 255);
    font-weight: 600;
    border-width: 0px;
    padding-left: 2.5rem;
    padding-right: 1.7rem;
    padding-top: 0.6rem;
    padding-bottom: 0.5rem;
    border-radius: 0.25rem;
    font-size: large;
  }

  .about-button-work-with:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  .about-button-work-with:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }

  .about-button-past-work {
    margin-left: 0rem;
    display: inline-flex;
    font-weight: 600;
    --tw-text-opacity: 1;
    color: #003140;
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    border-width: 0px;
    padding-left: 1.4rem;
    padding-right: 1.4rem;
    padding-top: 0.6rem;
    padding-bottom: 0.5rem;
    border-radius: 0.25rem;
    font-size: large;
  }

  .about-button-past-work:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  .about-button-past-work:hover {
    color: #fff;
    background-color: #003140;
    outline: 2px solid;
  }
}

@media (min-width: 769px) {
  .about-container {
    flex-direction: row;
  }

  .about-menu {
    width: 50%;
    padding-right: 4rem;
    align-items: flex-start;
    text-align: left;
    margin-bottom: 0rem;
  }
  .image-on-right-side-about-section {
    align-items: flex-start;
  }
}

@media (min-width: 1024px) {
  .about-menu {
    flex-grow: 1;
    padding-right: 6rem;
  }
  .introduction-text1 {
    display: inline-block;
  }

  .image-on-right-side-about-section {
    /* max-width: 32rem;  makes the image stay a fixed size the whole time.*/
    width: 100%;
  }
}
