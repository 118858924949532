* {
  text-decoration: none;
  scroll-behavior: smooth; 
  -webkit-overflow-scrolling: touch;
  animation: transitionIn 0.5s;
}
html, body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
  /* text-align: center; */
  width: 100%;
  overflow-x: hidden;
  height: 100%;
  background-color:#003140;
}

@keyframes transitionIn {
  from {
    opacity: 0;
    transform: rotateX(-10px);
  }

  to {
    opacity: 1;
    transform: rotateX(0);
  }
}
